import { graphql, useStaticQuery } from 'gatsby';

// PRODUCTS
export const fetchAllProducts = () => {
	const data = useStaticQuery(graphql`
		{
			allWpSimpleProduct {
				totalCount
				nodes {
					related {
						relatedProducts: nodes {
							name
							slug
							databaseId
							sku
							description
							image {
								sourceUrl
								altText
							}
							... on WpSimpleProduct {
								id
								name
								price
								regularPrice
								onSale
								stockStatus
							}
						}
					}

					name
					id
					slug
					description
					databaseId
					sku
					stockStatus
					salePrice
					image {
						sourceUrl
						altText
					}
					promotions {
						onPromotion
						additionalTag
					}
					productTags {
						brandName: nodes {
							name
							id
							slug
						}
					}
					productCategories {
						cat: nodes {
							name
							id
							slug
						}
					}
					... on WpSimpleProduct {
						id
						name
						price
						regularPrice
						onSale
						salePrice
						stockStatus
						tierPrice {
							tierPrice {
								price
								price2
								price3
								price4
							}
						}
					}
				}
			}

			allWpVariableProduct {
				nodes {
					name
					id
					slug
					description
					databaseId
					sku
					stockStatus
					salePrice
					image {
						sourceUrl
						altText
					}
					promotions {
						onPromotion
						additionalTag
					}
					productTags {
						brandName: nodes {
							name
							id
							slug
						}
					}

					... on WpVariableProduct{
            
            		id
						name
						price
						regularPrice
						onSale
						salePrice
						stockStatus
						tierPrice {
							tierPrice {
								price
								price2
								price3
								price4
							}
						}
          			}
        		}
			}
		}
	`);

	 return [
        ...data.allWpSimpleProduct.nodes,
        ...data.allWpVariableProduct.nodes
    ];
};
